import { registerApplication, start } from "single-spa";
import { authService } from "@talygen/authservice";
import initAuthService from "./initAuthService";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as prefix from "./activity-functions";
import Allurl from "./register-app-urls";
// alert(Allurl.appUrls[0].docstorage)
if (
  !localStorage.getItem(Allurl.appUrls[0].loginurl)
  //!localStorage.getItem("oidc.user:https://devlogin.talygen.devlabs.com:talygenspa")
  // !localStorage.getItem("oidc.user:https://applogin.talygen.com:talygenspa")
  //!localStorage.getItem("oidc.user:https://qatestlogin.talygen.com:talygenspa")
  // !localStorage.getItem("oidc.user:https://stagelogin.talygen.com:talygenspa")
  // !localStorage.getItem("oidc.user:https://usiclogin.talygen.com:talygenspa")
) {
  if (
    location.pathname == "/DownloadPublicFile" ||
    location.pathname == "DownloadPublicFolder"
  ) {
    registerApplication({
      name: "@talygen/docstorage",
      app: () => System.import(Allurl.appUrls[0].docstorageapp),
      //app: () => System.import("http://localhost:9005/app.js"),
      //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/app.js"),
      //app: () => System.import("https://talygenv.github.io/talygenui/Stage/DocStorage/dist/app.js"),
      //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/DocStorage/dist/app.js"),
      activeWhen: (location) =>
        prefix.prefix(location, ["DownloadPublicFile", "DownloadPublicFolder"]),
      customProps: {
        userProfile: userProfileInfo,
        classicEditor: ClassicEditor,
        ckEditor: CKEditor,
      },
    });
  } else {
    initAuthService(location.pathname);
    var userProfileInfo = null;
    authService.getUser().then(function (response) {
      userProfileInfo = response;
      registerApplication({
        name: "@talygen/navbar",
        app: () => System.import(Allurl.appUrls[0].navbarapp),
        //app: () => System.import("@talygen/navbar"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/NavBar/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/NavBar/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/NavBar/dist/app.js"),
        activeWhen: "/",
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/crm",
        app: () => System.import(Allurl.appUrls[0].crmapp),
        // app: () => System.import("http://localhost:9003/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CRM/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CRM/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CRM/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "CRM",
            "Brand",
            "CommunicationModes",
            "CRMReport",
            "logout",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/expenses",
        app: () => System.import(Allurl.appUrls[0].expensesapp),
        // app: () => System.import("http://localhost:9004/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Expense/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Expense/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/Expense/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Expenses",
            "ExpenseApproval",
            "ExpenseReport",
            "ExpensesHistory",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/docstorage",
        app: () => System.import(Allurl.appUrls[0].docstorageapp),
        //  app: () => System.import("http://localhost:9005/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/DocStorage/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/DocStorage/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "File",
            "ViewFileDetails",
            "RecyleBin",
            "Storage",
            "MyDashboard",
            "ViewFolderDetails",
            "AdvancedSetup",
            "UserStorageReport",
            "UserStorage",
            "ManageTags",
            "DownloadPublicFile",
            "DownloadPublicFolder",
            "files",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/ticketing",
        app: () => System.import(Allurl.appUrls[0].ticketingapp),
        //app: () => System.import("http://localhost:9006/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Ticketing/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Ticketing/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "FSM",
            "Ticketing",
            "RuleEngine",
            "ServiceAppointment",
            "FormBuilder",
            "ReplyTemplate",
            "TicketReport",
            "KnowledgeBase",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/assetmanagement",
        app: () => System.import(Allurl.appUrls[0].assetmanagementapp),

        // app: () => System.import("http://localhost:9007/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/AssetManagement/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/AssetManagement/dist/app.js"),
        // app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/AssetManagement/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Asset",
            "AssetAssignment",
            "AssetRequisition",
            "AssetCatalog",
            "AssetReport",
            "AdvancedSetups",
            "ProductType",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/commonui",
        app: () => System.import(Allurl.appUrls[0].commonuiapp),

        // app: () => System.import("http://localhost:9008/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CommonUi/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CommonUi/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CommonUi/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Help",
            // "HelpAndGuide",
            // "HelpArticles",
            // "UserManual",
            // "HelpArticlesPerModule",
            // "VideoTutorials",
            "Report",
            // "SetupGuides",
            "Dashboard",
            "unauthorized",
            "Configuration",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/timer",
        app: () => System.import(Allurl.appUrls[0].timerapp),

        //app: () => System.import("http://localhost:9009/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Timer/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Timer/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "TimeTracking",
            "TimerReports",
            "TimesheetApproval",
            "Timer",
            "WorkDiary",
            "Project",
            "Timesheet",
            "TimeApproval",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/approvalmodule",
        app: () => System.import(Allurl.appUrls[0].approvalmoduleapp),

        // app: () => System.import("http://localhost:9010/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Approval/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Approval/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "ApprovalRule",
            "ApprovalChain",
            "ApprovalGroup",
            "ApprovalReport",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
          ckEditor: CKEditor,
        },
      });
      registerApplication({
        name: "@talygen/customlayout",
        app: () => System.import(Allurl.appUrls[0].customlayoutapp),

        //app: () => System.import("http://localhost:9011/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CustomLayout/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, ["CustomLayout", "Custom"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/companysetup",
        app: () => System.import(Allurl.appUrls[0].companysetupapp),
        //app: () => System.import("http://localhost:9012/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CompanySetup/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CompanySetup/dist/app.js"),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "Company",
            "AnnouncementMessage",
            "CompanyHoliday",
            "Category",
            "Channel",
            "CurrencyManagement",
            "Department",
            "Designation",
            "EmailTemplate",
            "Shift",
            "Skill",
            "Roles",
            "Break",
            "CannedReply",
            "CompanyNotification",
            "ContactType",
            "Feedback",
            "Greeting",
            "IndustryType",
            "Immigration",
            "Location",
            "LoginAuthorization",
            "PaymentGateway",
            "ProvisionalRight",
            "ReviewTemplate",
            "Service",
            "Stage",
            "Status",
            "Storage",
            "UserTitle",
            "AdvanceSetup",
            "User",
            "Priority",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/ucaas",
        app: () => System.import(Allurl.appUrls[0].ucaasapp),
        //app: () => System.import("http://localhost:9013/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CustomLayout/dist/app.js"),
        activeWhen: (location) => prefix.prefix(location, ["Communication"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/billing",
        app: () => System.import(Allurl.appUrls[0].billingapp),

        //app: () => System.import("http://localhost:9011/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
        //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CustomLayout/dist/app.js"),
        activeWhen: (location) => prefix.prefix(location, ["ClientInvoice"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/webbuilder",
        app: () => System.import(Allurl.appUrls[0].WebBuildersetupapp),
        activeWhen: (location) => prefix.prefix(location, ["WebBuilder"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/hr",
        app: () => System.import(Allurl.appUrls[0].hrsetupapp),
        activeWhen: (location) =>
          prefix.prefix(location, [
            "JobPost",
            "HR",
            "ExitPolicy",
            "vendoruser",
            "Policy",
            "EmployeeSharing",
            "JobPostReport",
            "Attendance",
            "AttendanceApproval",
            "LeaveApproval",
            "HRReport",
            "DisputeApproval",
          ]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/performancereview",
        app: () => System.import(Allurl.appUrls[0].performancereview),
        activeWhen: (location) => prefix.prefix(location, ["Review"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/talygenreports",
        app: () => System.import(Allurl.appUrls[0].talygenreports),
        activeWhen: (location) =>
          prefix.prefix(location, ["Reports", "HRReport"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
      registerApplication({
        name: "@talygen/projectui",
        app: () => System.import(Allurl.appUrls[0].projectuiapp),
        activeWhen: (location) => prefix.prefix(location, ["Project"]),
        customProps: {
          userProfile: userProfileInfo,
          classicEditor: ClassicEditor,
        },
      });
    });
  }
} else {
  var userProfileInfo = JSON.parse(
    localStorage.getItem(Allurl.appUrls[0].loginurl)
    //localStorage.getItem("oidc.user:https://devlogin.talygen.devlabs.com:talygenspa")
    //  localStorage.getItem("oidc.user:https://applogin.talygen.com:talygenspa")
    //localStorage.getItem("oidc.user:https://qatestlogin.talygen.com:talygenspa")
    //localStorage.getItem("oidc.user:https://stagelogin.talygen.com:talygenspa")
  );
  registerApplication({
    name: "@talygen/navbar",
    app: () => System.import(Allurl.appUrls[0].navbarapp),
    // app: () => System.import("@talygen/navbar"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/NavBar/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/NavBar/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/NavBar/dist/app.js"),

    activeWhen: "/",
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/crm",
    app: () => System.import(Allurl.appUrls[0].crmapp),
    //app: () => System.import("http://localhost:9003/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CRM/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CRM/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/UsicTest/CRM/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "CRM",
        "Brand",
        "CommunicationModes",
        "CRMReport",
        "logout",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/expenses",
    app: () => System.import(Allurl.appUrls[0].expensesapp),
    //app: () => System.import("http://localhost:9004/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Expense/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Expense/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Expenses",
        "ExpenseApproval",
        "ExpenseReport",
        "ExpensesHistory",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/docstorage",
    app: () => System.import(Allurl.appUrls[0].docstorageapp),
    //app: () => System.import("http://localhost:9005/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/DocStorage/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/DocStorage/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "File",
        "ViewFileDetails",
        "RecyleBin",
        "Storage",
        "MyDashboard",
        "ViewFolderDetails",
        "AdvancedSetup",
        "UserStorageReport",
        "UserStorage",
        "ManageTags",
        "DownloadPublicFile",
        "DownloadPublicFolder",
        "files",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/ticketing",
    app: () => System.import(Allurl.appUrls[0].ticketingapp),
    //app: () => System.import("http://localhost:9006/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Ticketing/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Ticketing/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "FSM",
        "Ticketing",
        "RuleEngine",
        "ServiceAppointment",
        "FormBuilder",
        "ReplyTemplate",
        "TicketReport",
        "KnowledgeBase",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/assetmanagement",
    app: () => System.import(Allurl.appUrls[0].assetmanagementapp),
    //app: () => System.import("http://localhost:9007/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/AssetManagement/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/AssetManagement/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Asset",
        "AssetAssignment",
        "AssetRequisition",
        "AssetCatalog",
        "AssetReport",
        "AdvancedSetups",
        "ProductType",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/commonui",

    app: () => System.import(Allurl.appUrls[0].commonuiapp),
    //  app: () => System.import("http://localhost:9008/app.js"),
    // app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CommonUi/dist/app.js"),
    // app: () => System.import("https://talygenv.github.io/talygenui/Stage/CommonUi/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Help",
        // "HelpAndGuide",
        // "HelpArticles",
        // "UserManual",
        // "HelpArticlesPerModule",
        // "VideoTutorials",
        "Report",
        // "SetupGuides",
        "Dashboard",
        "unauthorized",
        "Configuration",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/timer",
    app: () => System.import(Allurl.appUrls[0].timerapp),
    //app: () => System.import("http://localhost:9009/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Timer/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Timer/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "TimeTracking",
        "TimerReports",
        "TimesheetApproval",
        "Timer",
        "WorkDiary",
        "Project",
        "Timesheet",
        "TimeApproval",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/approvalmodule",
    app: () => System.import(Allurl.appUrls[0].approvalmoduleapp),
    //app: () => System.import("http://localhost:9010/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/Approval/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/Approval/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "ApprovalRule",
        "ApprovalChain",
        "ApprovalGroup",
        "ApprovalReport",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
      ckEditor: CKEditor,
    },
  });
  registerApplication({
    name: "@talygen/customlayout",
    app: () => System.import(Allurl.appUrls[0].customlayoutapp),

    //app: () => System.import("http://localhost:9011/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CustomLayout/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, ["CustomLayout", "Custom"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/companysetup",
    app: () => System.import(Allurl.appUrls[0].companysetupapp),

    //app: () => System.import("http://localhost:9012/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CompanySetup/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CompanySetup/dist/app.js"),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "Company",
        "AnnouncementMessage",
        "CompanyHoliday",
        "Category",
        "Channel",
        "CurrencyManagement",
        "Department",
        "Designation",
        "EmailTemplate",
        "Shift",
        "Skill",
        "Roles",
        "Break",
        "CannedReply",
        "CompanyNotification",
        "ContactType",
        "Feedback",
        "Greeting",
        "IndustryType",
        "Immigration",
        "Location",
        "LoginAuthorization",
        "PaymentGateway",
        "ProvisionalRight",
        "ReviewTemplate",
        "Service",
        "Stage",
        "Status",
        "Storage",
        "UserTitle",
        "AdvanceSetup",
        "User",
        "Priority",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/ucaas",
    app: () => System.import(Allurl.appUrls[0].ucaasapp),
    //app: () => System.import("http://localhost:9013/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CustomLayout/dist/app.js"),
    activeWhen: (location) => prefix.prefix(location, ["Communication"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/billing",
    app: () => System.import(Allurl.appUrls[0].billingapp),

    //app: () => System.import("http://localhost:9011/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenuiqa/QATestNew/CustomLayout/dist/app.js"),
    //app: () => System.import("https://talygenv.github.io/talygenui/Stage/CustomLayout/dist/app.js"),
    activeWhen: (location) => prefix.prefix(location, ["ClientInvoice"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/webbuilder",
    app: () => System.import(Allurl.appUrls[0].WebBuildersetupapp),
    activeWhen: (location) => prefix.prefix(location, ["WebBuilder"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/hr",
    app: () => System.import(Allurl.appUrls[0].hrsetupapp),
    activeWhen: (location) =>
      prefix.prefix(location, [
        "JobPost",
        "HR",
        "ExitPolicy",
        "vendoruser",
        "Policy",
        "EmployeeSharing",
        "JobPostReport",
        "Attendance",
        "AttendanceApproval",
        "LeaveApproval",
        "HRReport",
        "DisputeApproval",
      ]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/performancereview",
    app: () => System.import(Allurl.appUrls[0].performancereview),
    activeWhen: (location) => prefix.prefix(location, ["Review"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/talygenreports",
    app: () => System.import(Allurl.appUrls[0].talygenreports),
    activeWhen: (location) => prefix.prefix(location, ["Reports", "HRReport"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
  registerApplication({
    name: "@talygen/projectui",
    app: () => System.import(Allurl.appUrls[0].projectuiapp),
    activeWhen: (location) => prefix.prefix(location, ["Project"]),
    customProps: {
      userProfile: userProfileInfo,
      classicEditor: ClassicEditor,
    },
  });
}

start();
